<template>
  <b-card>
    <ValidationObserver>
      <form @submit.prevent="createItem">
        <b-row>
          <b-col cols="12" md="6">
            <div class="form-group">
              <label for="name">Name:</label>
              <validation-provider
                name="name"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <input
                  id="name"
                  type="text"
                  class="form-control"
                  :class="classes"
                  :state="errors.length > 0 ? false : null"
                  v-model="formPayload.name"
                  placeholder="Judul"
                />
                <small
                  v-for="(validation, index) in validations.name"
                  :key="`errorName${index}`"
                  class="text-danger"
                  >{{ validation }}</small
                >
              </validation-provider>
            </div>
            <div class="form-group">
              <label for="content">Content:</label>
              <validation-provider name="content" rules="required">
                <b-form-textarea
                  rows="8"
                  type="text"
                  class="form-control"
                  v-model="formPayload.content"
                  maxlength="500"
                />
                <small
                  v-for="(validation, index) in validations.content"
                  :key="`errorName${index}`"
                  class="text-danger"
                  >{{ validation }}</small
                >
              </validation-provider>
            </div>
          </b-col>

          <b-col cols="12" md="6">
            <div class="form-group">
              <label for="startDate">Start Date:</label>
              <validation-provider name="startDate" rules="required">
                <flat-pickr
                  id="startDate"
                  v-model="formPayload.date_range.start_date"
                  class="form-control"
                  placeholder="Select Date..."
                  :config="{ dateFormat: 'Y-m-d' }"
                />
                <small
                  v-for="(validation, index) in validations.start_date"
                  :key="`errorName${index}`"
                  class="text-danger"
                  >{{ validation }}</small
                >
              </validation-provider>
            </div>
            <div class="form-group">
              <label for="endDate">End Date:</label>
              <validation-provider name="endDate" rules="required">
                <flat-pickr
                  id="endDate"
                  v-model="formPayload.date_range.end_date"
                  class="form-control"
                  placeholder="Select Date..."
                  :config="{ dateFormat: 'Y-m-d' }"
                />
                <small
                  v-for="(validation, index) in validations.end_date"
                  :key="`errorName${index}`"
                  class="text-danger"
                  >{{ validation }}</small
                >
              </validation-provider>
            </div>
            <div class="form-group">
              <label for="timeline">Timeline:</label>
              <br />
              <validation-provider name="timeline" rules="required">
                <v-select
                  id="timeline"
                  label="title"
                  v-model="formPayload.timeline_uuid"
                  :options="timelineList"
                  placeholder="-- Pilih --"
                  :reduce="(timelineList) => timelineList.uuid"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  @keyup.stop.native="searchTimeline"
                >
                </v-select>
                <small class="text-danger">{{
                  validations.timeline_uuid
                }}</small>
              </validation-provider>
            </div>
            <div class="form-group">
              <label for="">Image</label><br />
              <validation-provider name="image" rules="required">
                <b-form-file
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  @change="changeFile($event)"
                />
                <small>Current file:</small><br />
                <img
                  v-if="
                    file_url !== null ||
                    file_type == 'jpeg' ||
                    file_type == 'png' ||
                    file_type == 'jpg'
                  "
                  :src="file_url"
                  class="media_file"
                />
              </validation-provider>
            </div>
          </b-col>
        </b-row>

        <b-col cols="12" class="p-0 mt-2">
          <button
            variant="primary"
            class="btn waves-effect waves-float waves-light btn-primary"
            :disabled="isLoading"
          >
            {{ isLoading ? "Submitting..." : "Submit" }}
          </button>
          &nbsp; &nbsp;
          <a
            @click="handleBackButton()"
            variant="secondary"
            class="btn waves-effect waves-float waves-light btn-secondary"
          >
            Cancel
          </a>
        </b-col>
      </form>
    </ValidationObserver>
  </b-card>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
import vSelect from "vue-select";
import _ from "lodash";
import {
  BCard,
  BFormGroup,
  BFormRadio,
  BRow,
  BCol,
  BFormFile,
  BFormCheckbox,
  BFormTextarea,
} from "bootstrap-vue";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
} from "vee-validate";
import { required } from "@validations";
import { successNotification, errorNotification } from "@/auth/utils";
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
    // ...
  },
});
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormRadio,
    BFormCheckbox,
    vSelect,
    BFormFile,
    BFormTextarea,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },

  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },

  data() {
    return {
      required,
      moment,
      isLoading: false,
      formPayload: {
        name: "",
        content: "",
        date_range: {
          start_date: "",
          end_date: "",
        },
        timeline_uuid: "",
        images: [],
      },
      file_url: null,
      file_type: "",
      fileContent: "file",
      timelineList: [],
      validations: "",
      // Must be an array reference!
    };
  },
  mounted() {
    this.loadTimeline();
  },
  methods: {
    handleBackButton() {
      history.back();
    },
    changeFile(event) {
      this.formPayload.images = event.target.files[0];
      this.showFile(event.target.files[0]);
    },
    showFile(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.file_url = e.target.result;
        var str = e.target.result;
        var startIndex = str.indexOf("/") + 1; // Menentukan indeks mulai setelah karakter '/'
        var endIndex = str.indexOf(";"); // Menentukan indeks akhir sebelum karakter ';'
        var imageType = str.substring(startIndex, endIndex); // Memotong string berdasarkan indeks mulai dan akhir
        this.file_type = imageType;
      };
      reader.readAsDataURL(file);
    },
    searchTimeline: _.debounce(function (e) {
      const search = e.target.value;
      this.loadTimeline(search);
    }, 300),
    loadTimeline(search) {
      this.$http
        .get("/api/v1/admin/ffl-timelines", {
          params: {
            search: search != null ? search : null,
          },
        })
        .then((response) => {
          this.timelineList = response.data.data.items;
        });
    },
    preparePayload() {
      const form = new FormData();
      for (const key in this.formPayload) {
        if (this.formPayload.hasOwnProperty(key)) {
          if (key === "date_range") {
            for (const dateKey in this.formPayload[key]) {
              if (this.formPayload[key].hasOwnProperty(dateKey)) {
                form.append(
                  `date_range[${dateKey}]`,
                  this.formPayload[key][dateKey]
                );
              }
            }
          } else {
            form.append(key, this.formPayload[key]);
          }
        }
      }
      return form;
    },
    createItem() {
      var form = this.preparePayload();
      this.isLoading = true;
      this.$http
        .post("/api/v1/admin/ffl-timeline-activities", form)
        .then((response) => {
          this.isLoading = false;
          successNotification(
            this,
            "Success",
            "FFL Timeline Activity successfully created"
          );
          this.$router.push({ name: "female-future-leader-timeline-activity" });
        })
        .catch((error) => {
          if (error.response.data.meta.validations) {
            const validations = error.response.data.meta.validations;

            for (const field in validations) {
              if (validations.hasOwnProperty(field)) {
                validations[field] = validations[field].join(", ");
              }
            }

            this.validations = validations;
          } else {
            errorNotification(this, "Error", error.response.data.meta.messages);
          }
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.image__hint {
  font-size: 10px;
  margin-bottom: 0;
}
.media_file {
  width: 100%;
  height: 300px;
  object-fit: contain;
}
</style>
